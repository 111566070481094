<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition
        appear
      >
        <base-material-card
          :color="'primary'"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
          style="background: rgba(255,255,255, .6) !important;"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Desconectado
              </h1>
            </div>
          </template>
          <v-spacer />
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-card-text
                class="text-center accent-4"
              >
                <label>Você foi desconectado. Clique no botão abaixo para refazer o login.</label>
                <pages-btn
                  large
                  color="primary"
                  depressed
                  class="v-btn--text white--text mt-5"
                  @click="goToLogin"
                >
                  Ir para login
                </pages-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { UserService } from "@/code/services/user/userService";

export default {
  mounted() {
    let nextUrl = "";
    if (this.$router.params) {
      nextUrl = this.$router.params.nextUrl;
    }

    ////////////////////////////////////////////
    //método antigo de login, refazer essa parte
    this.$store.commit('setToken', null);
    // localStorage.removeItem(userKey);
    ////////////////////////////////////////////

    const service = new UserService();
    service.logout(nextUrl);
  },
  methods: {
    goToLogin: function() {
      if (this.$router.params) {
        this.$router.push({ name: "Login", query: { nextUrl: this.$router.params } });
      } else {
        this.$router.push({ name: "Login" });
      }
    }
  },
  components: {
    PagesBtn: () => import('@/components/pages/Btn'),
  },
};
</script>
